<template>

    <tr class="text-center align-middle">
        <!-- OS Type -->
        <td>
            <span class="badge bg-success me-2" v-if="instance.attributes.tags['protected']">
                <i class="bi-lock"></i>
            </span>
            <i class="fa-brands" :class="{'fa-ubuntu' : instance.attributes.tags['osType'] === 'linux', 'fa-microsoft': instance.attributes.tags['osType'] === 'windows'}"></i>
        </td>

        <!-- Name -->
        <td>
            {{instance.name}} <small class="text-muted"><em>({{instance.attributes.id}})</em></small>
        </td>

        <!-- Root disk -->
        <td>
            {{instance.attributes.root_block_device[0].volume_size}}
        </td>

        <!-- Core(s) -->
        <td>
            {{instance.attributes.cpu_core_count}} x CPU(s) <small class="text-muted"><em>({{instance.attributes.cpu_threads_per_core}} Thread(s))</em></small>
        </td>

        <!-- IP -->
        <td>
            {{instance.attributes.private_ip}}
        </td>

        <!-- Actions -->
        <td class="text-end" v-if="infra.status === 3">
            
            <!-- If not protected -->
            <div class="btn-group me-2" role="group" v-if="!instance.attributes.tags['protected']">
                <!-- if is started -->
                <button class="btn btn-secondary btn-sm" v-if="instance.attributes.instance_state === 'running'" @click="btnClicked('Stop')">
                    <i class="bi bi-stop-fill"></i>
                </button>
                <!-- if is stopped -->
                <button class="btn btn-secondary btn-sm" v-if="instance.attributes.instance_state === 'stopped'" @click="btnClicked('Start')">
                    <i class="bi bi-play-fill"></i>
                </button>
                <!-- The rebuild button (based on ami image) -->
                <button class="btn btn-secondary btn-sm disabled">
                    <i class="bi bi-recycle"></i>
                </button>
                <!-- The trash button to destroy instance -->
                <button class="btn btn-danger btn-sm" @click="btnClicked('Trash')">
                    <i class="bi bi-trash"></i>
                </button>
            </div>

            <!-- If protected -->
            <div class="btn-group me-2" role="group" v-else>
                <!-- Restart button -->
                <button title="Forcer un redémarrage" class="btn btn-secondary btn-sm" v-if="instance.attributes.instance_state === 'running'" @click="showConfirmBoxRestart = true">
                    <i class="bi bi-arrow-repeat"></i>
                </button>
                <!-- The rebuild button (based on ami image)-->
                <button class="btn btn-secondary btn-sm" @click="showConfirmBoxRebuild = true">
                    <i class="bi bi-recycle"></i>
                </button>
            </div>

            <!-- If moderator -->
            <!-- <button class="btn btn-success btn-sm disabled" v-if="userInfo.authLevel <= 1" :class="{'disabled': instance.attributes.instance_state === 'running'}">
                <i class="bi bi-folder-symlink"></i>
            </button> -->
            <button title="Capture d'écran distante" class="btn btn-secondary btn-sm" :class="{'disabled': instance.attributes.instance_state === 'stopped'}" @click="showScreenShotUser = true">
                <i class="bi bi-camera-fill"></i>
            </button>
        </td>
        <td v-else class="text-end">
            <!-- No actions possible if infra is in stopped state -->
        </td>

    </tr>

    <!-- Modal for stop confirmation -->
    <div class="modal fade" tabindex="-1" :id="'DescribeMachineStopModal_' + instance.name" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Arrêter '{{instance.name}}'</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="!loading"></button>
                </div>
                <div class="modal-body">
                    <p>Êtes vous certain de vouloir arrêter cette instance ?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :class="{disabled: loading}">Annuler</button>
                    <button type="button" class="btn btn-danger" :class="{disabled: loading}" @click="startStopping">
                        <span class="spinner spinner-border spinner-border-sm me-2" v-if="loading"></span>
                        <i class="bi bi-stop-fill me-2" ></i>Stopper
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal for start confirmation -->
    <div class="modal fade" tabindex="-1" :id="'DescribeMachineStartModal_' + instance.name" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Arrêter '{{instance.name}}'</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="!loading"></button>
                </div>
                <div class="modal-body">
                    <p>Êtes vous certain de vouloir démarrer cette instance ?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :class="{disabled: loading}">Annuler</button>
                    <button type="button" class="btn btn-success" :class="{disabled: loading}" @click="startStarting">
                        <span class="spinner spinner-border spinner-border-sm me-2" v-if="loading"></span>
                        <i class="bi bi-play-fill me-2" ></i>Démarrer
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Modal for restart confirmation -->
    
    <!-- Modal for rebuild confirmation -->
    
    <!-- Modal for trash confirmation -->
    <div class="modal fade" tabindex="-1" :id="'DescribeMachineTrashModal_' + instance.name" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Supprimer '{{instance.name}}'</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="!loading"></button>
                </div>
                <div class="modal-body">
                    <p>Êtes vous certain de vouloir supprimer cette instance ?</p>
                    <p>Si oui, veuillez entrer son nom dans le champs ci-dessous et cliquez sur 'Détruire'.</p>
                    <div class="mb-3">
                        <input type="text" class="form-control" :placeholder="instance.name" v-model="trashName" :disabled="trashName === instance.name">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :class="{disabled: loading}">Annuler</button>
                    <button type="button" class="btn btn-danger" :class="{disabled: trashName !== instance.name || loading}" @click="startDeletion">
                        <span class="spinner spinner-border spinner-border-sm me-2" v-if="loading"></span>
                        <i class="bi bi-trash me-2" ></i>Détruire
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Modal for convert confirmation -->

    <!-- Modal for confirmation message only -->
    <div class="modal fade" tabindex="-1" :id="'DescribeMachineConfirmModal_' + instance.name" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <p>{{confirmationMessage}}</p>
                    <div class="alert alert-warning mb-0" v-if="errorMessage">
                        <pre class="mb-0">{{errorMessage}}</pre>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="close()">Fermer</button>
                </div>
            </div>
        </div>
    </div>

    <ScreenShotView :modal-id="'ScreenShotUser_'+instance.attributes.id"
        :show="showScreenShotUser"
        :infra_id="infra._id"
        :instance_id="instance.attributes.id"
        @close="showScreenShotUser = false" />

    <ConfirmBox :modal-id="'ConfirmBoxRestart_'+instance.attributes.id"
        :show="showConfirmBoxRestart"
        title="Restart"
        message="Êtes vous certain de vouloir redémarrer cette instance ?"
        type-of="question"
        @decline="showConfirmBoxRestart = false"
        @close="showConfirmBoxRestart = false"
        @confirm="rebootInstance()" />

    <ConfirmBox :modal-id="'ConfirmBoxRebuild_'+instance.attributes.id"
        :show="showConfirmBoxRebuild"
        title="Reconstruire"
        message="Êtes vous certain de vouloir reconstruire cette instance ? Toutes ses données par défaut seront restaurées."
        type-of="question"
        @decline="showConfirmBoxRebuild = false"
        @close="showConfirmBoxRebuild = false"
        @confirm="rebuildInstance()" />

</template>

<script setup>
    import { defineProps, ref, onMounted, defineEmits } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from 'axios';
    import ScreenShotView from './ScreenShotView.vue';
    import ConfirmBox from '../../admin/components/generics/ConfirmBox.vue';

    let loading = ref(false);
    let confirmationMessage = ref("");
    let errorMessage = ref("");

    let trashName = ref("");
    let showScreenShotUser = ref(false);

    let showConfirmBoxRestart = ref(false);
    let showConfirmBoxRebuild = ref(false);

    const props = defineProps([
        "userInfo",
        "instance",
        "infra"
    ]);

    const emits = defineEmits([
        "reload"
    ])

    // Trigger modal specified by Name
    function btnClicked(btnName){
        var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachine${btnName}Modal_${props.instance.name}`));
        myModal.toggle();
    }

    // When confirmation dialog is closed
    function close(){
        let myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
        myModal.toggle();
        emits("reload");
    }

    // Stop instance action
    function startStopping(){
        loading.value = true;
        errorMessage.value  = "";
        confirmationMessage.value = "";

        axios.post(`/api/users/me/infras/${props.infra._id}/instances/${props.instance.name}/stop`)
            .then( answer => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineStopModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Votre demande d'arrêt à bien été prise en compte."
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })
            .catch( answer => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineStopModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Une erreur est survenue..."
                errorMessage.value = answer;
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })
    }
    
    // Start instance action
    function startStarting(){
        loading.value = true;
        errorMessage.value  = "";
        confirmationMessage.value = "";

        axios.post(`/api/users/me/infras/${props.infra._id}/instances/${props.instance.name}/start`)
            .then( answer => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineStartModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Votre demande de démarrage à bien été prise en compte."
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })
            .catch( error => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineStartModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Une erreur est survenue..."
                errorMessage.value = (error.response.data) ? error.response.data : error;
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })
    }

    // Start deletion action
    function startDeletion(){
        loading.value = true;
        errorMessage.value  = "";
        confirmationMessage.value = "";

        axios.delete(`/api/users/me/infras/${props.infra._id}/instances/${props.instance.name}`)
            .then( answer => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineTrashModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Votre demande de suppresion à bien été prise en compte."
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })
            .catch( error => {
                var myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineTrashModal_${props.instance.name}`));
                myModal.toggle();
                confirmationMessage.value = "Une erreur est survenue..."
                errorMessage.value = (error.response.data) ? error.response.data : error;
                myModal = Modal.getOrCreateInstance(document.querySelector(`#DescribeMachineConfirmModal_${props.instance.name}`));
                myModal.toggle();
                loading.value = false;
            })

    }

    function rebootInstance(){
        showConfirmBoxRestart.value = false;
        loading.value = true;
        errorMessage.value  = "";
        confirmationMessage.value = "";

        axios.post(`/api/users/me/infras/${props.infra._id}/instances/${props.instance.attributes.id}/reboot`)
            .then( answer => {
                loading.value = false;
                emits("reload");
            })
            .catch( answer => {
                loading.value = false;
            })
    }
    
    function rebuildInstance(){
        showConfirmBoxRebuild.value = false;
        loading.value = true;
        errorMessage.value  = "";
        confirmationMessage.value = "";

        axios.post(`/api/users/me/infras/${props.infra._id}/instances/${props.instance.attributes.id}/rebuild`)
            .then( answer => {
                loading.value = false;
                emits("reload");
            })
            .catch( answer => {
                loading.value = false;
            })
    }

</script>

<style>

</style>