<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content" v-if="visible">

                    <div class="modal-header">
                        <h6 class="modal-title">ScreenShot</h6>
                        <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                    </div>

                    <div class="modal-body text-center">
                        <img :src="imgData" class="img-thumbnail" v-if="imgData"/>
                        <span class="spinner spinner-border" v-else></span>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm" @click.prevent="load()" :disabled="!loaded">
                            <i class="bi bi-camera-fill me-2"></i>Reload
                        </button>
                        <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled">
                            <i class="bi bi-x me-2"></i>Fermer
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, computed, ref, toRefs } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";

    let props = defineProps(["modalId","show","infra_id","instance_id"]);
    let emits = defineEmits(["close"]);
    let disabled = ref(false);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
            load();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    // Manage loading state
    let loaded = ref(false);
    let imgData = ref(null);

    onMounted( () => {

    })

    function load(){
        loaded.value = false;
        imgData.value = null;
        axios.get(`/api/users/me/infras/${props.infra_id}/instances/${props.instance_id}/screenshot?output=b64`)
            .then( answer => {
                imgData.value = `data:image/jpeg;base64,${answer.data.raw}`;
                loaded.value = true;
            })
            .catch( e =>{
                alert(e);
            })
    }

    function close(){
        emits("close");
    }

</script>