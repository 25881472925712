<template>

    <div class="container" v-if="!loaded">
        <div class="row">
            <div class="alert alert-dark my-3">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <i><small>Chargement en cours...</small></i>
            </div>
        </div>
    </div>

    <div class="container" v-else>

        
        <!-- Display only if no infra -->
        <div class="row" v-if="infras.length === 0">
            <div class="col mt-3">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-text">
                            <i class="bi bi-exclamation-triangle-fill me-2 text-warning"></i>
                            Attention
                        </h6>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Vous ne disposez pas encore d'infrastructure. Veuillez en <router-link :to="{name: 'Dashboard.datacenters'}">créer</router-link> une avant de pouvoir continuer dans cette section.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Display general informations -->
        <div class="row" v-else>
            <div class="col">
                <div class="card my-3">
                    <div class="card-header">
                        <h5 class="card-text"><i class="bi bi-inbox me-2"></i>Liste de vos machines</h5>
                    </div>
                    <div class="card-body">
                        
                        <p class="card-text">Ici vous pouvez manager l'ensemble de vos machines. Elles sont classées en fonction de vos infrastructures existantes.</p>
                        
                        <div class="card-text">
                            <label for="" class="form-label">Infrastructure</label>
                            <select name="" id="" class="form-select" v-model="selectedInfra">
                                <option :value="infra" v-for="infra in infras" :key="infra._id">{{ infra.name }}</option>
                                <option selected value="0">--</option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
        <!-- Display all instances of the user  -->
        <div class="row">
            <div class="col">

                <div class="card mb-3" v-if="selectedInfra !== '0'">
                    <div class="card-header">
                        <h6 class="card-text">
                            <i class="bi bi-building me-2"></i><small class="text-muted"><em>Infra n°{{selectedInfra._id}}</em></small>
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="card-text" v-if="instances[selectedInfra._id].length === 0">Machine(s) en attente...</div>
                        <!-- <div class="card-text" v-if="selectedInfra.status !== InfraStatusCode.STARTED && selectedInfra.status !== InfraStatusCode.UPDATING">Infra non démarée...</div> -->
                        <!-- <template v-if="selectedInfra.status === InfraStatusCode.STARTED || selectedInfra.status === InfraStatusCode.UPDATING"> -->
                        <template v-else>

                            <div class="alert alert-warning mb-3" v-if="selectedInfra.status !== 3">
                                <p class="mb-0"><i class="bi bi-info-circle-fill text-info me-2"></i>Cette infrastructure est à l'arrêt ou une mise à niveau est en cours. Veuillez la démarrer ou attendre que les changements soient appliqués pour pouvoir en modifier les états.</p>
                            </div>

                            <table class="table" :class="{'table-active': selectedInfra.status !== 3}">
                                <thead>
                                    <tr class="text-center">
                                        <th>Type</th>
                                        <th>Nom</th>
                                        <th>Disque</th>
                                        <th>Coeur(s)</th>
                                        <th>Ip</th>
                                        <th class="text-end" v-if="selectedInfra.status === 3">Actions</th>
                                        <th v-else></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <describe-machine-vue :user-info="userInfo" :instance="instance" :infra="selectedInfra" v-for="instance in instances[selectedInfra._id]" :key="instance.id" @reload="reload()" />
                                </tbody>
                            </table>
                        </template>
                    </div>
                    <div class="card-footer">

                    </div>
                </div>

            </div>
        </div>

    </div>

</template>

<script setup>

    import axios from "axios";
    import {defineProps, onMounted, ref} from "vue";
    import { useRouter } from "vue-router";
    import DescribeMachineVue from "./components/DescribeMachine.vue";

    const router = useRouter();

    let loaded = ref(false);
    let infras = ref([]);
    let instances = ref({});
    let selectedInfra = ref("0");

    let InfraStatusCode = {
        "DEFINED": 0,
        "PENDING": 1,
        "STARTING": 2,
        "STARTED": 3,
        "STOPPING": 4,
        "STOPPED": 5,
        "DELETING": 6,
        "UPDATING": 7
    }

    defineProps([
        "userInfo"
    ])

    onMounted( () => {
        load();
    })

    function reload(){
        // document.location = "/";
        router.push("/");
    }

    function load(){
        loaded.value = false;
        axios.get("/api/users/me/infras")
            .then( answer => {
                infras.value = answer.data.infrasResponse.filter( i => i.status !== InfraStatusCode.DELETING );
                if(infras.value.length === 0) return loaded.value = true;

                let count = 0;
                infras.value.forEach( infra => {
                    axios.get(`/api/users/me/infras/${infra._id}/instances`)
                        .then( instancesAnswer => {
                            instances.value[infra._id] = instancesAnswer.data.infraInstancesResponse;
                            count++;
                            if(count === infras.value.length) loaded.value = true;
                        })
                        .catch( err => {
                            alert(err);
                        })
                })

            })
            .catch( err => {
                alert(err);
            })
    }

</script>

<style>

</style>